<template>
  <div v-if="$member" class="member-panel">
    <div class="member-head">
      <div class="avatar-pic" data-tooltip-title="點擊更換您的頭像" />
      <div style="flex-grow:1;">
        <div class="avatar-name">
          {{ $member.name }}
        </div>
        <div v-if="$member.firm" class="avatar-department">
          {{ $member.firm.name }} {{ $member.firm_job_title }}
        </div>
      </div>
      <div class="sign-out">
        <div class="ui button third small" @click="signout">
          登出
        </div>
      </div>
    </div>
    <div class="member-bottom">
      <div class="row">
        <div class="col-title">
          信箱
        </div>
        <div class="col-data">
          <span> {{ $member.email }} </span>
        </div>
      </div>
      <div class="row">
        <div class="col-title">
          密碼
        </div>
        <div class="col-data">
          <span>**********</span>
          <component :is="requireConfirmation ? 'div' : 'router-link'" :to="{ name: 'MemberPasswd' }" class="operation" @click="navigate">
            <i class="icon-lock icons" />變更密碼
          </component>
        </div>
      </div>
      <div class="row">
        <div class="col-title">
          手機
        </div>
        <div class="col-data">
          <span v-if="$member.mobile_number">{{ $member.mobile_number }}</span>
          <span v-else>尚未設定</span>
          <component :is="requireConfirmation ? 'div' : 'router-link'" :to="{ name: 'ModifyInfo' }" class="operation" @click="navigate">
            <i class="icon-lock icons" />變更手機
          </component>
        </div>
      </div>
      <div class="row">
        <div class="col-title">
          生日
        </div>
        <div class="col-data">
          <span v-if="$member.birth_date">{{ $member.birth_date | date }}</span>
          <span v-else>尚未設定</span>
          <component :is="requireConfirmation ? 'div' : 'router-link'" :to="{ name: 'ModifyInfo' }" class="operation" @click="navigate">
            <i class="icon-calendar icons" />更改
          </component>
        </div>
      </div>
      <div class="row">
        <div class="col-title">
          性別
        </div>
        <div class="col-data">
          <span v-if="$member.gender === 'Male'">男</span>
          <span v-if="$member.gender === 'Female'">女</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MixinNotifiable } from 'modules/ui/notification.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [
    MixinNotifiable,
  ],
  computed: {
    ...mapGetters('member', [
      'requireConfirmation',
    ])
  },
  mounted() {
    document.addEventListener('click', this.detectClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.detectClickOutside)
  },
  methods: {
    ...mapActions('member', {
      actionSignout: 'signout',
    }),
    signout() {
      this.actionSignout()
      if (this.$route.name !== 'MemberSignin') {
        this.$router.replace({ name: 'MemberSignin' }) // 回到入口頁
      }
    },
    detectClickOutside(event) {
      const isClickOutside = !jQuery.contains(this.$el, event.target)
      if (isClickOutside) this.$emit('closePanel', event.target)
    },
    navigate(event) {
      if (this.requireConfirmation) {
        this.notify('請先完成驗證您的帳號')
        this.$router.replace({
          name: 'MemberConfirm'
        }).catch(() => {})
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.member-panel
  width 400px
  border 1px solid #EDEDED
  border-radius 21px
  background-color #fff
  +under(sm)
    width 100%
    height calc(100vh - 49px)
    border-radius 0
.member-head
  display flex
  padding 1.5rem
  border-bottom 1px solid #EDEDED
  .avatar-pic
    width 42px
    height 42px
    flex-shrink 0
    border-radius 50%
    margin 2px 1rem 2px 0
    box-shadow 0px 1px 1px rgba(170, 170, 170, 0.2)
    background-image url('~images/avatar-blue.svg')
    background-size cover
    background-position center center
    background-repeat no-repeat
    cursor pointer
  .avatar-name
    font-size 1.2rem
    font-weight bold
  .avatar-department
    color $gray2
    margin-top .5rem
    line-height 18px
  .sign-out
    display flex
    align-items center
    flex 0 0 59.5px
    margin-left .75rem
    border-left 1px solid #ededed
    padding-left .75rem
.member-bottom
  padding 1.5rem
  .row
    display flex
    line-height 18px
    + .row
      margin-top 1rem
  .col-title
    font-weight bold
    margin-right 2rem
  .col-data
    flex-grow 1
    display flex
    justify-content space-between
  .operation
    width 84px
    cursor pointer
    color $gray2
    .icons
      margin-right .5rem
</style>
